import './MainCSS/App.css';
import React from 'react';
import Home from './Viewables/Home'

export default class App extends React.Component{
  render(){
    return(
    <Home/>
    )
  }
}

//Hey... whatcha doin? Scram!